import Chart from 'chart.js/auto';
import { formatMoneyToNumber, formatCurrencyWholeNumber } from '@/layouts/application/utils/formats';
import { autoFormatMoneyFields } from '@/layouts/application/utils/handleMoneyField';
import Tooltip from '@/layouts/application/utils/charts/tooltipPositionCustom';
export default class InvestmentIllustratorChart {
  constructor($chartElementContainer) {
    this.chartElementContainer = $chartElementContainer;
    this.cumulativeDeposits = [];
    this.netInvestmentValueEndOfYear = [];
    this.payoutAmount = [];
    this.netInvestmentValueEndOfYearWithoutDrawdown = [];
    this.netInvestmentValueEndOfYearAfterDrawdown = [];
    this.totalPayoutAmount = [];
    this.monthlyAmountNeedRec1 = [];
    this.monthlyAmountNeedRec2 = [];
    this.maxYearsToInvest = [];
    this.recDepositEndAge = [];
    this.projectWorthWithoutReinvesting = [];
    this.projectWorthWithReinvesting = [];
    this.dividendPayoutReceived = [];
    this.dividendPayout = [];
    this.netInvestmentValueDividendWithoutReinvest = [];
    this.netInvestmentValueDividendWithReinvest = [];
    this.cumulativeDepositsDividend = [];
    this.totalDividendPayoutWithReinvest = [];
    this.totalDividendPayoutWithNoReinvest = [];
    this.yearlyPayoutWithReinvest = [];
    this.yearlyPayoutWithNoReinvest = [];
    this.investmentIllustratorForm = '#investment-illustrator-form';
    this.investmentTimeFrameSelector = '.investment-timeframe';
    this.depositAgeRangeSelector = '.deposit-age-range';
    this.yearlyPayoutAmountSelector = '.yearly-payout-amount';
    this.payoutRangeAgeSelector = '.payout-range-age';
    this.depositAmountMonthlySelector = '.deposit-amount-monthly';
    this.investmentRateSelector = '.investment-rate-return';
    this.inflationSelector = '.inflation';
    this.dividendSelector = '.dividend';
    this.dividendYieldValue = '#investment_illustrator_dividend_yield';
    this.dividendCheckBox = '#investment_illustrator_dividend_check';
    this.ageFrom = '#investment_illustrator_age_from';
    this.ageTo = '#investment_illustrator_age_to';
    this.depositStartAge = '#investment_illustrator_deposit_start_age';
    this.depositEndAge = '#investment_illustrator_deposit_end_age';
    this.payoutType = '#investment_illustrator_payout_type';
    this.yearlyPayoutAmount = '#investment_illustrator_yearly_payout_amount';
    this.payoutStartAge = '#investment_illustrator_payout_start_age';
    this.payoutEndAge = '#investment_illustrator_payout_end_age';
    this.depositAmount = '#investment_illustrator_deposit_amount';
    this.investmentRate = '#investment_illustrator_investment_rate_return';
    this.inflationRate = '#investment_illustrator_inflation_rate';
    this.inflationCheckbox = '#investment_illustrator_inflation_checkbox';
  }

  investmentIllustratorChart = undefined;

  presentDataToChart(topup_value, withdrawal, form_input) {
    this.showLoadingDreamChart();
    if ($(this.payoutType).val() == 'fixed_amount') {
      $.post(
        Routes.present_investment_illustrator_chart_consultant_investment_illustrators_path(),
        {
          age_start: form_input.age_start,
          age_end: form_input.age_end,
          deposit_start_age: form_input.deposit_start_age,
          deposit_end_age: form_input.deposit_end_age,
          payout_amount_yearly: form_input.payout_amount_yearly,
          payout_start_age: form_input.payout_start_age,
          payout_end_age: form_input.payout_end_age,
          deposit_amount_monthly: form_input.deposit_amount_monthly,
          investment_rate_return: form_input.investment_rate_return,
          inflation_rate: form_input.inflation_rate,
          lumpsum_topup: topup_value,
          lumpsum_withdrawal: withdrawal,
        },
        (data) => {
          this.cumulativeDeposits = data.chart_data.cumulative_deposits;
          this.netInvestmentValueEndOfYear = data.chart_data.net_investment_value_end_of_year;
          this.payoutAmount = data.chart_data.payout_amount;
          this.totalPayoutAmount = data.chart_data.total_payout_amount;
          this.netInvestmentValueEndOfYearWithoutDrawdown =
            data.chart_data.net_investment_end_of_year_without_drawdowns;
          this.netInvestmentValueEndOfYearAfterDrawdown = data.chart_data.net_investment_end_of_year_after_drawdowns;
          this.monthlyAmountNeedRec1 = data.chart_data.monthly_amount_need_rec1;
          this.monthlyAmountNeedRec2 = data.chart_data.monthly_amount_need_rec2;
          this.maxYearsToInvest = data.chart_data.max_years_to_invest;
          this.recDepositEndAge = data.chart_data.rec_end_deposit_age;
          this.drawInvestmentIlustratorChart({
            cumulativeDeposits: Object.values(this.cumulativeDeposits),
            netInvestmentValueEndOfYear: Object.values(this.netInvestmentValueEndOfYear),
            payoutAmount: Object.values(this.payoutAmount),
            chartElementContainer: this.chartElementContainer,
          });
        },
      );
    } else {
      $.post(
        Routes.present_investment_illustrator_chart_dividend_consultant_investment_illustrators_path(),
        {
          age_start: form_input.age_start,
          age_end: form_input.age_end,
          deposit_start_age: form_input.deposit_start_age,
          deposit_end_age: form_input.deposit_end_age,
          payout_amount_yearly: form_input.payout_amount_yearly,
          payout_start_age: form_input.payout_start_age,
          payout_end_age: form_input.payout_end_age,
          deposit_amount_monthly: form_input.deposit_amount_monthly,
          investment_rate_return: form_input.investment_rate_return,
          dividend_yield: form_input.dividend_yield,
          lumpsum_topup: topup_value,
          lumpsum_withdrawal: withdrawal,
          reinvest: form_input.reinvest,
        },
        (data) => {
          if (form_input.reinvest) {
            this.projectWorthWithoutReinvesting = data.chart_data.project_worth_without_reinvesting;
            this.projectWorthWithReinvesting = data.chart_data.project_worth_with_reinvesting;
            this.cumulativeDepositsDividend = data.chart_data.cumulative_deposits_dividend;
            this.totalDividendPayoutWithReinvest = data.chart_data.total_dividend_payout_with_reinvest;
            this.totalDividendPayoutWithNoReinvest = data.chart_data.total_dividend_payout_with_no_reinvest;
            this.netInvestmentValueDividendWithoutReinvest = data.chart_data.net_investment_value_without_reinvest;
            this.netInvestmentValueDividendWithReinvest = data.chart_data.net_investment_value_with_reinvest;
            this.yearlyPayoutWithReinvest = data.chart_data.yearly_payout_with_reinvest;

            this.drawInvestmentIlustratorChart({
              projectWorthWithoutReinvesting: Object.values(this.projectWorthWithoutReinvesting),
              projectWorthWithReinvesting: Object.values(this.projectWorthWithReinvesting),
              yearlyPayoutWithReinvest: Object.values(this.yearlyPayoutWithReinvest),
              chartElementContainer: this.chartElementContainer,
            });
          } else {
            this.dividendPayoutReceived = data.chart_data.dividend_payout_received;
            this.netInvestmentValueDividendWithoutReinvest = data.chart_data.net_investment_value_without_reinvest;
            this.totalDividendPayoutWithReinvest = data.chart_data.total_dividend_payout_with_reinvest;
            this.totalDividendPayoutWithNoReinvest = data.chart_data.total_dividend_payout_with_no_reinvest;
            this.netInvestmentValueDividendWithReinvest = data.chart_data.net_investment_value_with_reinvest;
            this.cumulativeDepositsDividend = data.chart_data.cumulative_deposits_dividend;
            this.dividendPayout = data.chart_data.dividend_payout;
            this.yearlyPayoutWithNoReinvest = data.chart_data.yearly_payout_no_reinvest;

            this.drawInvestmentIlustratorChart({
              netInvestmentValueDividendWithoutReinvest: Object.values(this.netInvestmentValueDividendWithoutReinvest),
              chartElementContainer: this.chartElementContainer,
              yearlyPayoutWithNoReinvest: Object.values(this.yearlyPayoutWithNoReinvest),
              cumulativeDepositsDividend: Object.values(this.cumulativeDepositsDividend),
            });
          }
        },
      );
    }
  }

  drawInvestmentIlustratorChart(chart_data) {
    this.initInvestmentIllustratorChart(chart_data);
  }

  initInvestmentIllustratorChart(chart_data) {
    let datasets = [];
    if (!this.investmentIllustratorChart && !window.location.toString().includes('clients')) {
      $(this.payoutType).val('fixed_amount');
    }

    if ($(this.payoutType).val() == 'fixed_amount') {
      datasets = this.setDataSets({
        cumulativeDeposits: chart_data.cumulativeDeposits,
        netInvestmentValueEndOfYear: chart_data.netInvestmentValueEndOfYear,
        payoutAmount: chart_data.payoutAmount,
        chartElementContainer: chart_data.chartElementContainer,
      });
    } else if ($(this.payoutType).val() == 'dividend' && $(this.dividendCheckBox).is(':checked')) {
      datasets = this.setDataSets({
        projectWorthWithoutReinvesting: chart_data.projectWorthWithoutReinvesting,
        projectWorthWithReinvesting: chart_data.projectWorthWithReinvesting,
        yearlyPayoutWithReinvest: chart_data.yearlyPayoutWithReinvest,
        chartElementContainer: chart_data.chartElementContainer,
      });
    } else {
      datasets = this.setDataSets({
        netInvestmentValueDividendWithoutReinvest: chart_data.netInvestmentValueDividendWithoutReinvest,
        cumulativeDepositsDividend: chart_data.cumulativeDepositsDividend,
        yearlyPayoutWithNoReinvest: chart_data.yearlyPayoutWithNoReinvest,
        chartElementContainer: chart_data.chartElementContainer,
      });
    }
    const data = {
      labels: this.inclusiveRange(Number($(this.ageFrom).val()), Number($(this.ageTo).val()), 1),
      datasets: datasets,
    };

    if (this.investmentIllustratorChart) {
      const options = this.updateOptionsChart();
      this.investmentIllustratorChart.data = data;
      this.investmentIllustratorChart.options.plugins = options;
      this.investmentIllustratorChart.update();
    } else {
      this.investmentIllustratorChart = new Chart(chart_data.chartElementContainer, {
        type: 'line',
        data: data,
        options: {
          scales: {
            y: {
              grid: {
                display: false,
              },
              ticks: {
                callback: function (value, index, ticks) {
                  return formatCurrencyWholeNumber(value);
                },
              },
            },
            x: {
              grid: {
                display: false,
              },
              ticks: {
                autoSkip: true,
                maxTicksLimit: 24,
              },
            },
          },
          plugins: {
            tooltip: {
              mode: 'index',
              position: 'tooltipPositionCustom',
            },
            legend: {
              position: 'bottom',
            },
          },
        },
        plugins: [
          {
            afterUpdate: (chart) => {
              this.hideLoadingDreamChart();
              if ($(this.payoutType).val() == 'fixed_amount') {
                let value_net_investment_end_of_year_after_drawdown_sanitize = [];
                let value_net_investment_end_of_year_sanitize = [];
                let value_target_retirement = $(this.payoutStartAge).val();
                let ages_below_target = [];
                let first_age_below_target = [];

                Object.keys(this.netInvestmentValueEndOfYearAfterDrawdown).forEach((key) => {
                  let value = this.netInvestmentValueEndOfYearAfterDrawdown[key];
                  if (value < 0) {
                    value_net_investment_end_of_year_after_drawdown_sanitize.push(key);
                  }
                });
                
                Object.keys(this.netInvestmentValueEndOfYear).forEach((key) => {
                  let value = this.netInvestmentValueEndOfYear[key];
                  if (value === 0) {
                    value_net_investment_end_of_year_sanitize.push(key);
                    if (key < value_target_retirement) {
                      ages_below_target.push(key);
                    } else if (key > value_target_retirement && first_age_below_target.length === 0) {
                      first_age_below_target.push(key);
                    }
                  }
                });
                let ages_below_target_after_retirement = [...ages_below_target, first_age_below_target];

                $('.fixed-amount .first-left').html(
                  `By the time you are ${$(this.payoutStartAge).val() - 1
                  } years old, you would have contributed ${formatCurrencyWholeNumber(
                    this.cumulativeDeposits[`${$(this.payoutStartAge).val() - 1}`],
                  )} and your investment value could be worth ${formatCurrencyWholeNumber(
                    this.netInvestmentValueEndOfYearWithoutDrawdown[`${$(this.payoutStartAge).val() - 1}`],
                  )}.`,
                );
                $('.fixed-amount .first-right').html(
                  `<span class="font-size-34-text"><b>${formatCurrencyWholeNumber(
                    this.netInvestmentValueEndOfYearWithoutDrawdown[`${$(this.payoutStartAge).val() - 1}`],
                  )}</b></span> <span class="font-size-16-text align-self-end">Growth of ${Number(
                    (this.netInvestmentValueEndOfYearWithoutDrawdown[`${$(this.payoutStartAge).val() - 1}`] /
                      this.cumulativeDeposits[`${$(this.payoutStartAge).val() - 1}`] -
                      1) *
                    100,
                  ).toFixed(0)}%</span>`,
                );
                $('.fixed-amount .first-section').html(`At the age of ${Number($(this.ageTo).val())}`);
                $('.fixed-amount .contribution-value').html(
                  `${formatCurrencyWholeNumber(this.cumulativeDeposits[`${$(this.ageTo).val()}`])}`,
                );
                $('.fixed-amount .payout-value').html(
                  `${formatCurrencyWholeNumber(Number(Math.abs(this.totalPayoutAmount).toFixed(0)))}`,
                );
                $('.fixed-amount .remaining-value').html(
                  `${formatCurrencyWholeNumber(this.netInvestmentValueEndOfYear[`${$(this.ageTo).val()}`])}`,
                );
                $('.fixed-amount .project-worth-value').html(
                  `${formatCurrencyWholeNumber(
                    Number(Math.abs(this.totalPayoutAmount).toFixed(0)) +
                    Number(this.netInvestmentValueEndOfYear[`${$(this.ageTo).val()}`]),
                  )}`,
                );

                if (this.netInvestmentValueEndOfYear[`${$(this.payoutEndAge).val()}`] > 0) {
                  $('.fixed-amount .third-note').css('backgroundColor', '#F2F5FA');
                  $('.fixed-amount .fourth-note').css('display', 'none');
                  $('.fixed-amount .recommendation').html('');
                  $('.fixed-amount .recommendation-content-1').html('');
                  $('.fixed-amount .recommendation-content-2').html('');
                  $('.fixed-amount .announcement-content-2').html('');
                  $('.announcement-icon').addClass('d-none');
                  $('.fixed-amount .announcement').html('<b>Congratulations!</b>');
                  $('.fixed-amount .announcement-content-1').html(
                    `<span class="font-size-14-text">Based on your desired payout amount of $${$(
                      this.yearlyPayoutAmount,
                    ).val()} you will be able to receive these yearly payouts until the age of ${$(
                      this.payoutEndAge,
                    ).val()} years old!</span>`,
                  );
                } else {
                  $('.third-note').css('backgroundColor', '#FAE5E5');
                  $('.fourth-note').css('backgroundColor', '#d9ead3');
                  $('.fourth-note').css('display', 'block');
                  $('.announcement-icon').removeClass('d-none');
                  $('.announcement').html(`<span class="font-weight-600-text">Take note!</span>`);
                  $('.announcement-content-1').html(
                    `<span class="font-size-14-text">Your funds would be insufficient at ages ${ages_below_target_after_retirement.join(', ')} and based on your desired payout amount of $${$(
                      this.yearlyPayoutAmount,
                    ).val()}, your investment will only be able to last you till ${value_net_investment_end_of_year_sanitize[0]
                    } years old!</span>`,
                  );
                  // $('.announcement-content-2').html(
                  //   `<span class="font-size-14-text">You will not be able to receive your desired payouts for ${$(this.payoutEndAge).val() - value_net_investment_end_of_year_sanitize[0]
                  //   } years!</span>`,
                  // );
                  $('.recommendation').html(`<span class="font-weight-600-text">Recommendation</span>`);
                  $('.recommendation-content-1').html(
                    `<span class="font-size-14-text">To achieve your ideal payout amount, you would need to invest <span class="font-size-20-text font-weight-700-text">${formatCurrencyWholeNumber(
                      this.monthlyAmountNeedRec1,
                    )} a month</span> for the same period stated above. (${$(this.depositEndAge).val() - $(this.depositStartAge).val() + 1
                    } years)</span>`,
                  );
                  if (
                    $(this.depositEndAge).val() - $(this.depositStartAge).val() + 1 < 30 &&
                    $(this.payoutStartAge).val() - $(this.depositStartAge).val() >
                    $(this.depositEndAge).val() - $(this.depositStartAge).val()
                  ) {
                    $('.recommendation-content-2').html(
                      `<span class="font-size-14-text">or</span><br /><span class="font-size-14-text">You can consider to maximise your investment timeframe and invest <b>${formatCurrencyWholeNumber(
                        this.monthlyAmountNeedRec2,
                      )} a month</b> for a period of <b>${this.maxYearsToInvest} years</b> from age ${$(
                        this.depositStartAge,
                      ).val()} till age ${this.recDepositEndAge}!</span>`,
                    );
                  }
                }
                $('.dividend').hide();
                $('.fixed-amount').show();
              } else {
                $('.fixed-amount').hide();
                $('.dividend').show();
                if ($(this.dividendCheckBox).is(':checked')) {
                  $('.dividend .third-note').addClass('d-flex');
                  $('.dividend .third-note').show();

                  $('.dividend .first-note .note-1').html(
                    `If you did not reinvest your dividends, your end projected worth would be ${formatCurrencyWholeNumber(
                      this.totalDividendPayoutWithNoReinvest +
                      this.netInvestmentValueDividendWithoutReinvest[`${$(this.ageTo).val()}`],
                    )}`,
                  );

                  $('.dividend .first-note .note-2').html(
                    `But if you were to reinvest your dividends, your end projected worth would be ${formatCurrencyWholeNumber(
                      this.netInvestmentValueDividendWithReinvest[`${$(this.ageTo).val()}`],
                    )}.`,
                  );

                  $('.dividend .first-right').html(
                    `<span class="font-size-34-text"><b>${formatCurrencyWholeNumber(
                      this.netInvestmentValueDividendWithReinvest[`${$(this.ageTo).val()}`],
                    )}</b></span> <span class="font-size-16-text align-self-end">${Number(
                      (this.netInvestmentValueDividendWithReinvest[`${$(this.ageTo).val()}`] /
                        (this.totalDividendPayoutWithNoReinvest +
                          this.netInvestmentValueDividendWithoutReinvest[`${$(this.ageTo).val()}`]) -
                        1) *
                      100,
                    ).toFixed(0)}% increase!</span>`,
                  );

                  $('.dividend .second-note .first-section').html(
                    `At the age of ${Number($(this.ageTo).val())}, without reinvesting dividends`,
                  );

                  $('.dividend .second-note .second-section .contribution-value').html(
                    `${formatCurrencyWholeNumber(this.cumulativeDepositsDividend[`${$(this.ageTo).val()}`])}`,
                  );
                  $('.dividend .second-note .second-section .payout-value').html(
                    `${formatCurrencyWholeNumber(this.totalDividendPayoutWithNoReinvest)}`,
                  );
                  $('.dividend .second-note .second-section .project-worth-value').html(
                    `${formatCurrencyWholeNumber(
                      this.totalDividendPayoutWithNoReinvest +
                      this.netInvestmentValueDividendWithoutReinvest[`${$(this.ageTo).val()}`],
                    )}`,
                  );

                  $('.dividend .third-note .first-section').html(
                    `At the age of ${Number($(this.ageTo).val())}, if you reinvest dividends`,
                  );
                  $('.dividend .third-note .second-section .contribution-value').html(
                    `${formatCurrencyWholeNumber(this.cumulativeDepositsDividend[`${$(this.ageTo).val()}`])}`,
                  );
                  $('.dividend .third-note .second-section .payout-value').html(
                    `${formatCurrencyWholeNumber(this.totalDividendPayoutWithReinvest)}`,
                  );
                  $('.dividend .third-note .second-section .project-worth-value').html(
                    `${formatCurrencyWholeNumber(
                      this.netInvestmentValueDividendWithReinvest[`${$(this.ageTo).val()}`],
                    )}`,
                  );
                } else {
                  $('.dividend .third-note').removeClass('d-flex');
                  $('.dividend .third-note').hide();

                  $('.dividend .first-note .note-1').html(
                    `By the time you are ${$(
                      this.payoutStartAge,
                    ).val()} years old, you will receive your first dividend payout of ${formatCurrencyWholeNumber(
                      this.dividendPayoutReceived[`${$(this.payoutStartAge).val()}`],
                    )}`,
                  );

                  $('.dividend .first-note .note-2').html(
                    `At the age of ${$(
                      this.ageTo,
                    ).val()}, your end projected worth would have grown to ${formatCurrencyWholeNumber(
                      this.totalDividendPayoutWithNoReinvest +
                      this.netInvestmentValueDividendWithoutReinvest[`${$(this.ageTo).val()}`],
                    )}.`,
                  );

                  $('.dividend .first-right').html(
                    `<span class="font-size-34-text"><b>${formatCurrencyWholeNumber(
                      this.totalDividendPayoutWithNoReinvest +
                      this.netInvestmentValueDividendWithoutReinvest[`${$(this.ageTo).val()}`],
                    )}</b></span> <span class="font-size-16-text align-self-end">Growth of ${Number(
                      ((this.totalDividendPayoutWithNoReinvest +
                        this.netInvestmentValueDividendWithoutReinvest[`${$(this.ageTo).val()}`]) /
                        this.cumulativeDepositsDividend[`${$(this.ageTo).val()}`] -
                        1) *
                      100,
                    ).toFixed(0)}%</span>`,
                  );

                  $('.dividend .second-note .first-section').html(
                    `At the age of ${Number($(this.ageTo).val())}, without reinvesting dividends`,
                  );

                  $('.dividend .second-note .second-section .contribution-value').html(
                    `${formatCurrencyWholeNumber(this.cumulativeDepositsDividend[`${$(this.ageTo).val()}`])}`,
                  );
                  $('.dividend .second-note .second-section .payout-value').html(
                    `${formatCurrencyWholeNumber(this.totalDividendPayoutWithNoReinvest)}`,
                  );
                  $('.dividend .second-note .second-section .project-worth-value').html(
                    `${formatCurrencyWholeNumber(
                      this.totalDividendPayoutWithNoReinvest +
                      this.netInvestmentValueDividendWithoutReinvest[`${$(this.ageTo).val()}`],
                    )}`,
                  );
                }
              }
            },
          },
        ],
      });
    }
  }

  handleChangeFieldOnForm() {
    const inputSelectors = [
      this.investmentTimeFrameSelector,
      this.depositAgeRangeSelector,
      this.depositAmountMonthlySelector,
      this.payoutRangeAgeSelector,
      this.yearlyPayoutAmountSelector,
    ];

    $(inputSelectors.join(', ')).on('blur', 'input', () => {
      let age = '';

      const depositEndAge = Number($(this.depositEndAge).val());
      const ageTo = Number($(this.ageTo).val());
      const payoutEndAge = Number($(this.payoutEndAge).val());
      const depositStartAge = Number($(this.depositStartAge).val());
      const ageFrom = Number($(this.ageFrom).val());
      const payoutStartAge = Number($(this.payoutStartAge).val());
      const depositAmount = Number($(this.depositAmount).val());

      if (depositEndAge > ageTo || payoutEndAge > ageTo) {
        this.showLoadingDreamChart();
        age = 'end age';
        $('.investment-timeframe .error-message').html(
          `The investment ${age} cannot be smaller than deposit end age or payout end age`,
        );
      } else if (depositStartAge > depositEndAge) {
        this.showLoadingDreamChart();
        age = 'start age';
        $('.deposit-age-range .error-message').html(
          `The deposit ${age} cannot be larger than deposit end age`,
        );
      } else if (depositStartAge < ageFrom || payoutStartAge < ageFrom) {
        this.showLoadingDreamChart();
        age = 'start age';
        $('.investment-timeframe .error-message').html(
          `The investment ${age} cannot be larger than deposit start age or payout start age`,
        );
      } else if (depositAmount <= 0) {
        this.showLoadingDreamChart();
        $('.deposit-amount-monthly .error-message').html('Deposit amount must be greater than 0');
      } else {
        $('.investment-timeframe .error-message').html('');
        $('.deposit-amount-monthly .error-message').html('');
        $('.deposit-age-range .error-message').html('');
        this.renderChartBaseOnType();
      }
    });
  }

  handleChangeDividend() {
    $(`${this.payoutType}, ${this.dividendSelector}, ${this.inflationSelector}`).on('change', () => {
      this.renderChartBaseOnType();
    });
  }

  handleChangeInvestmentRate() {
    $(this.investmentRateSelector).on('blur', 'input', () => {
      const investmentRate = Number($(this.investmentRate).val())
      if (investmentRate <= 0) {
        this.showLoadingDreamChart();
        $('.investment-rate-return .error-message').html(`Investment rate of return cannot be 0 or negative`);
      } else {
        $('.investment-rate-return .error-message').html('');
        this.renderChartBaseOnType();
      }
    });
  }

  setup() {
    this.handleInflationCheckBox();
    this.changeDisplayInflationCheckbox();
    let inflation_rate = this.handleInflationInputValue();
    this.presentDataToChart(this.getExistLumpsumTopupValue(), this.getExistLumpsumwithdrawalValue(), {
      age_start: $(this.ageFrom).val(),
      age_end: $(this.ageTo).val(),
      deposit_start_age: $(this.depositStartAge).val(),
      deposit_end_age: $(this.depositEndAge).val(),
      payout_amount_yearly: Number(formatMoneyToNumber($(this.yearlyPayoutAmount).val())),
      payout_start_age: $(this.payoutStartAge).val(),
      payout_end_age: $(this.payoutEndAge).val(),
      deposit_amount_monthly: Number(formatMoneyToNumber($(this.depositAmount).val())),
      investment_rate_return: $(this.investmentRate).val(),
      inflation_rate: inflation_rate,
      dividend_yield: $(this.dividendYieldValue).val(),
      reinvest: $(this.dividendCheckBox).is(':checked'),
    });
    this.handleChangeFieldOnForm();
    this.handleChangeDividend();
    this.handleAddNewLumpSumTopup();
    this.handleAddNewLumpSumwithdrawal();
    this.triggerRemoveLumpSum();
    this.showBreakdownTable();
    this.handleFormatMoneyFields();
    this.showFormBaseOnPayoutType();
    this.togglePayoutType();
    this.handleChangeInvestmentRate();
    this.removeTypeChartSection()
  }

  removeTypeChartSection() {
    $(window).on("resize", function () {
      $(".investment-illustrator-content .left-content .charts-section").css("flex-basis", 0);
    })
  }

  togglePayoutType() {
    $(this.payoutType).on('change', () => {
      this.showFormBaseOnPayoutType();
    });
  }

  showFormBaseOnPayoutType() {
    if ($(this.payoutType).val() == 'dividend') {
      $(this.inflationSelector).removeClass('d-flex');
      $(this.yearlyPayoutAmountSelector).addClass('d-none');
      $(this.dividendSelector).removeClass('d-none');
      $(this.inflationSelector).hide();
    } else {
      $(this.yearlyPayoutAmountSelector).removeClass('d-none');
      $(this.inflationSelector).addClass('d-flex');
      $(this.dividendSelector).addClass('d-none');
      $(this.inflationSelector).show();
    }
  }

  inclusiveRange = (start, end, step) => {
    return Array.from(Array.from(Array(Math.ceil((end - start + 1) / step)).keys()), (x) => start + x * step);
  };

  handleInflationCheckBox() {
    if ($(this.inflationCheckbox).is(':checked')) {
      $(this.inflationRate).prop('disabled', false);
    } else {
      $(this.inflationRate).prop('disabled', true);
    }
  }

  changeDisplayInflationCheckbox() {
    $(this.inflationCheckbox).on('change', () => {
      this.handleInflationCheckBox();
    });
  }

  handleAddNewLumpSumTopup() {
    let eventBaseOnBrowser = this.detectBrowserToUseExactEvent();
    $('.add-new-lumpsum-topup').on('click', () => {
      var items_count = $('.lumpsum-topup').length;
      let new_input = `<div class="lumpsum-topup" id="lumpsum_topup_${items_count + 1}">
              <div class="d-flex justify-content-between mb-2">
                <h2 class="font-size-16-text"> Lump Sum Top-up ${items_count + 1}</h2>
                <button class="btn action remove-red remove-lumpsum-topup-button" data-form-id="lumpsum_topup_${items_count + 1
        }"></button>
              </div>
              <div class="form-group d-flex string optional mb-2 age-wrapper">
                <label class="string p-3 pr-5 optional font-size-14-text" for="topup_age_value_${items_count + 1
        }">Age</label>
                <input class="form-control string optional col-8" type='text' id="topup_age_value_${items_count + 1}">
              </div>
              <div class="form-group d-flex string optional amount-wrapper">
                <label class="string p-3 pr-4 optional font-size-14-text" for="topup_amount_${items_count + 1
        }">Amount</label>
                <input class="form-control string optional col-8" type='text' id="topup_amount_${items_count + 1}">
              </div>
      </div>`;
      $('#lumpsum-topup-content').append(new_input);
      for (let i = 1; i < $('.lumpsum-topup').length + 1; i++) {
        $(`#lumpsum_topup_${i}`).on('change', () => {
          this.renderChartBaseOnType();
        });

        $(`#lumpsum_topup_${i}`).on(`${eventBaseOnBrowser}`, `#topup_amount_${i}`, (e) => {
          autoFormatMoneyFields(e.target);
        });
      }
    });
  }

  handleAddNewLumpSumwithdrawal() {
    let eventBaseOnBrowser = this.detectBrowserToUseExactEvent();
    $('.add-new-lumpsum-withdrawal').on('click', () => {
      var items_count = $('.lumpsum-withdrawal').length;
      let new_input = `<div class="lumpsum-withdrawal" id="lumpsum_withdrawal_${items_count + 1}">
              <div class="d-flex justify-content-between mb-2">
                <h2 class="font-size-16-text"> Lump Sum Withdrawal ${items_count + 1}</h2>
                <button class="btn action remove-red remove-lumpsum-withdrawal-button" data-form-id="lumpsum_withdrawal_${items_count + 1
        }"></button>
              </div>
              <div class="form-group d-flex string optional mb-2 age-wrapper">
                <label class="string optional p-3 pr-5 font-size-14-text" for="withdrawal_age_value_${items_count + 1
        }">Age</label>
                <input class="form-control string optional col-8" type='text' id="withdrawal_age_value_${items_count + 1
        }">
              </div>
              <div class="form-group d-flex string optional amount-wrapper">
                <label class="string optional p-3 pr-4 font-size-14-text" for="withdrawal_amount_${items_count + 1
        }">Amount</label>
                <input class="form-control string optional col-8" type='text' id="withdrawal_amount_${items_count + 1}">
              </div>
      </div>`;
      $('#lumpsum-withdrawal-content').append(new_input);
      for (let i = 1; i < $('.lumpsum-withdrawal').length + 1; i++) {
        $(`#lumpsum_withdrawal_${i}`).on('change', () => {
          this.renderChartBaseOnType();
        });

        $(`#lumpsum_withdrawal_${i}`).on(`${eventBaseOnBrowser}`, `#withdrawal_amount_${i}`, (e) => {
          autoFormatMoneyFields(e.target);
        });
      }
    });
  }

  renderChartBaseOnType() {
    if ($(this.payoutType).val() == 'fixed_amount') {
      let inflation_rate = this.handleInflationInputValue();
      this.presentDataToChart(this.getExistLumpsumTopupValue(), this.getExistLumpsumwithdrawalValue(), {
        age_start: $(this.ageFrom).val(),
        age_end: $(this.ageTo).val(),
        deposit_start_age: $(this.depositStartAge).val(),
        deposit_end_age: $(this.depositEndAge).val(),
        payout_amount_yearly: Number(formatMoneyToNumber($(this.yearlyPayoutAmount).val())),
        payout_start_age: $(this.payoutStartAge).val(),
        payout_end_age: $(this.payoutEndAge).val(),
        deposit_amount_monthly: Number(formatMoneyToNumber($(this.depositAmount).val())),
        investment_rate_return: $(this.investmentRate).val(),
        inflation_rate: inflation_rate,
      });
    } else {
      this.presentDataToChart(this.getExistLumpsumTopupValue(), this.getExistLumpsumwithdrawalValue(), {
        age_start: $(this.ageFrom).val(),
        age_end: $(this.ageTo).val(),
        deposit_start_age: $(this.depositStartAge).val(),
        deposit_end_age: $(this.depositEndAge).val(),
        payout_amount_yearly: Number(formatMoneyToNumber($(this.yearlyPayoutAmount).val())),
        payout_start_age: $(this.payoutStartAge).val(),
        payout_end_age: $(this.payoutEndAge).val(),
        deposit_amount_monthly: Number(formatMoneyToNumber($(this.depositAmount).val())),
        investment_rate_return: $(this.investmentRate).val(),
        dividend_yield: $(this.dividendYieldValue).val(),
        reinvest: $(this.dividendCheckBox).is(':checked'),
      });
    }
  }

  triggerRemoveLumpSum() {
    const that = this;

    $('#additional-information').on(
      'click',
      '.remove-lumpsum-topup-button, .remove-lumpsum-withdrawal-button',
      function (e) {
        const $this = $(e.target);

        $(`#${$this.data('form-id')}`).remove();
        if ($this.hasClass('remove-lumpsum-topup-button')) {
          let arr = document.getElementsByClassName('lumpsum-topup');
          if ($('.lumpsum-topup').length == 1) {
            $('.lumpsum-topup').attr('id', 'lumpsum_topup_1');
            $(`#lumpsum_topup_1 h2`).html('Lump Sum Top-up 1');
            $(`#lumpsum_topup_1 button`).attr('data-form-id', 'lumpsum_topup_1');
            $('#lumpsum_topup_1 .age-wrapper label').attr('for', 'topup_age_value_1');
            $('#lumpsum_topup_1 .age-wrapper input').attr('id', 'topup_age_value_1');
            $('#lumpsum_topup_1 .amount-wrapper label').attr('for', 'topup_amount_1');
            $('#lumpsum_topup_1 .amount-wrapper input').attr('id', 'topup_amount_1');
          } else if ($('.lumpsum-topup').length > 0 && $('.lumpsum-topup').length != 1) {
            arr.forEach((topup, index) => {
              $(topup).attr('id', `lumpsum_topup_${index + 1}`);
              $(`#lumpsum_topup_${index + 1} h2`).html(`Lump Sum Top-up ${index + 1}`);
              $(`#lumpsum_topup_${index + 1} button`).attr('data-form-id', `lumpsum_topup_${index + 1}`);
              $(`#lumpsum_topup_${index + 1} .age-wrapper label`).attr('for', `topup_age_value_${index + 1}`);
              $(`#lumpsum_topup_${index + 1} .age-wrapper input`).attr('id', `topup_age_value_${index + 1}`);
              $(`#lumpsum_topup_${index + 1} .amount-wrapper label`).attr('for', `topup_amount_${index + 1}`);
              $(`#lumpsum_topup_${index + 1} .amount-wrapper input`).attr('id', `topup_amount_${index + 1}`);
            });
          }
        } else {
          let arr = document.getElementsByClassName('lumpsum-withdrawal');
          if ($('.lumpsum-withdrawal').length == 1) {
            $('.lumpsum-withdrawal').attr('id', 'lumpsum_withdrawal_1');
            $(`#lumpsum_withdrawal_1 h2`).html('Lump Sum Withdrawal 1');
            $(`#lumpsum_withdrawal_1 button`).attr('data-form-id', 'lumpsum_withdrawal_1');
            $('#lumpsum_withdrawal_1 .age-wrapper label').attr('for', 'withdrawal_age_value_1');
            $('#lumpsum_withdrawal_1 .age-wrapper input').attr('id', 'withdrawal_age_value_1');
            $('#lumpsum_withdrawal_1 .amount-wrapper label').attr('for', 'withdrawal_amount_1');
            $('#lumpsum_withdrawal_1 .amount-wrapper input').attr('id', 'withdrawal_amount_1');
          } else if ($('.lumpsum-withdrawal').length > 0 && $('.lumpsum-withdrawal').length != 1) {
            arr.forEach((withdrawal, index) => {
              $(withdrawal).attr('id', `lumpsum_withdrawal_${index + 1}`);
              $(`#lumpsum_withdrawal_${index + 1} h2`).html(`Lump Sum withdrawal ${index + 1}`);
              $(`#lumpsum_withdrawal_${index + 1} button`).attr('data-form-id', `lumpsum_withdrawal_${index + 1}`);
              $(`#lumpsum_withdrawal_${index + 1} .age-wrapper label`).attr('for', `withdrawal_age_value_${index + 1}`);
              $(`#lumpsum_withdrawal_${index + 1} .age-wrapper input`).attr('id', `withdrawal_age_value_${index + 1}`);
              $(`#lumpsum_withdrawal_${index + 1} .amount-wrapper label`).attr('for', `withdrawal_amount_${index + 1}`);
              $(`#lumpsum_withdrawal_${index + 1} .amount-wrapper input`).attr('id', `withdrawal_amount_${index + 1}`);
            });
          }
        }
        that.renderChartBaseOnType();
      },
    );
  }

  showBreakdownTable() {
    $('.view-breakdown-link').on('click', () => {
      if ($(this.payoutType).val() == 'fixed_amount') {
        $.get(
          Routes.investment_illustrator_breakdown_consultant_investment_illustrators_path({
            age_start: $(this.ageFrom).val(),
            age_end: $(this.ageTo).val(),
            deposit_start_age: $(this.depositStartAge).val(),
            deposit_end_age: $(this.depositEndAge).val(),
            payout_amount_yearly: Number(formatMoneyToNumber($(this.yearlyPayoutAmount).val())),
            payout_start_age: $(this.payoutStartAge).val(),
            payout_end_age: $(this.payoutEndAge).val(),
            deposit_amount_monthly: Number(formatMoneyToNumber($(this.depositAmount).val())),
            investment_rate_return: $(this.investmentRate).val(),
            inflation_rate: this.handleInflationInputValue(),
            lumpsum_topup: this.getExistLumpsumTopupValue(),
            lumpsum_withdrawal: this.getExistLumpsumwithdrawalValue(),
          }),
          (data) => {
            $('#investment-illustrator-breakdown-data').html('');
            $('#breakdown-investment-illustrator-table-header').html('');
            $('#breakdown-investment-illustrator-table-header').append(`
              <tr>
                <th scope="col" class="primary-sticky-header">Age</th>
                <th scope="col" class="primary-sticky-header">Deposit Amount</th>
                <th scope="col" class="primary-sticky-header">Total Deposits</th>
                <th scope="col" class="primary-sticky-header">Payout Amount</th>
                <th scope="col" class="primary-sticky-header">Total Payout</th>
                <th scope="col" class="primary-sticky-header">Net Investment Value</th>
              </tr>
            `);
            data.age_range.forEach((age, index) => {
              $('#investment-illustrator-breakdown-data').append(`<tr>
              <td>${age}</td>
              <td>${formatCurrencyWholeNumber(data.deposit_amount[index])}</td>
              <td>${formatCurrencyWholeNumber(data.cumulative_deposits[index])}</td>
              <td>${formatCurrencyWholeNumber(Math.abs(data.payout_amount[index]).toFixed(0))}</td>
              <td>${formatCurrencyWholeNumber(data.total_payout_breakdown[index])}</td>
              <td>${formatCurrencyWholeNumber(data.net_investment_value_end_of_year[index])}</td>
              </tr>`);
            });

            $('#investment-illustrator-modal').modal({
              backdrop: 'static',
              keyboard: false,
            });
          },
        );
      } else {
        if ($(this.payoutType).val() == 'dividend' && $(this.dividendCheckBox).is(':checked')) {
          $.get(
            Routes.investment_illustrator_breakdown_dividend_consultant_investment_illustrators_path({
              age_start: $(this.ageFrom).val(),
              age_end: $(this.ageTo).val(),
              deposit_start_age: $(this.depositStartAge).val(),
              deposit_end_age: $(this.depositEndAge).val(),
              payout_amount_yearly: Number(formatMoneyToNumber($(this.yearlyPayoutAmount).val())),
              payout_start_age: $(this.payoutStartAge).val(),
              payout_end_age: $(this.payoutEndAge).val(),
              deposit_amount_monthly: Number(formatMoneyToNumber($(this.depositAmount).val())),
              investment_rate_return: $(this.investmentRate).val(),
              lumpsum_topup: this.getExistLumpsumTopupValue(),
              lumpsum_withdrawal: this.getExistLumpsumwithdrawalValue(),
              reinvest: $(this.dividendCheckBox).is(':checked'),
              dividend_yield: $(this.dividendYieldValue).val(),
            }),
            (data) => {
              $('#investment-illustrator-breakdown-data').html('');
              $('#breakdown-investment-illustrator-table-header').html('');
              $('#breakdown-investment-illustrator-table-header').append(`
              <tr>
                <th scope="col" class="primary-sticky-header">Age</th>
                <th scope="col" class="primary-sticky-header">Deposit Amount</th>
                <th scope="col" class="primary-sticky-header">Total Deposits</th>
                <th scope="col" class="primary-sticky-header">Dividends (Reinvested)</th>
                <th scope="col" class="primary-sticky-header">Dividends (Payout)</th>
                <th scope="col" class="primary-sticky-header">Total Payout</th>
                <th scope="col" class="primary-sticky-header">Total Asset Value</th>
              </tr>
            `);
              data.age_range.forEach((age, index) => {
                $('#investment-illustrator-breakdown-data').append(`<tr>
                <td>${age}</td>
                <td>${formatCurrencyWholeNumber(data.deposit_amount[index])}</td>
                <td>${formatCurrencyWholeNumber(data.cumulative_deposits[index])}</td>
                <td>${formatCurrencyWholeNumber(data.dividend_payout_reinvest[index])}</td>
                <td>${formatCurrencyWholeNumber(0)}</td>
                <td>${formatCurrencyWholeNumber(data.cumulative_lumpsum_withdrawal[index])}</td>
                <td>${formatCurrencyWholeNumber(data.total_asset_value[index])}</td>
                </tr>`);
              });

              $('#investment-illustrator-modal').modal({
                backdrop: 'static',
                keyboard: false,
              });
            },
          );
        } else {
          $.get(
            Routes.investment_illustrator_breakdown_dividend_consultant_investment_illustrators_path({
              age_start: $(this.ageFrom).val(),
              age_end: $(this.ageTo).val(),
              deposit_start_age: $(this.depositStartAge).val(),
              deposit_end_age: $(this.depositEndAge).val(),
              payout_amount_yearly: Number(formatMoneyToNumber($(this.yearlyPayoutAmount).val())),
              payout_start_age: $(this.payoutStartAge).val(),
              payout_end_age: $(this.payoutEndAge).val(),
              deposit_amount_monthly: Number(formatMoneyToNumber($(this.depositAmount).val())),
              investment_rate_return: $(this.investmentRate).val(),
              lumpsum_topup: this.getExistLumpsumTopupValue(),
              lumpsum_withdrawal: this.getExistLumpsumwithdrawalValue(),
              reinvest: $(this.dividendCheckBox).is(':checked'),
              dividend_yield: $(this.dividendYieldValue).val(),
            }),
            (data) => {
              $('#investment-illustrator-breakdown-data').html('');
              $('#breakdown-investment-illustrator-table-header').html('');
              $('#breakdown-investment-illustrator-table-header').append(`
              <tr>
                <th scope="col">Age</th>
                <th scope="col">Deposit Amount</th>
                <th scope="col">Total Deposits</th>
                <th scope="col">Dividends (Reinvested)</th>
                <th scope="col">Dividends (Payout)</th>
                <th scope="col">Total Payout</th>
                <th scope="col">Total Asset Value</th>
              </tr>
            `);
              data.age_range.forEach((age, index) => {
                $('#investment-illustrator-breakdown-data').append(`<tr>
                <td>${age}</td>
                <td>${formatCurrencyWholeNumber(data.deposit_amount[index])}</td>
                <td>${formatCurrencyWholeNumber(data.cumulative_deposits[index])}</td>
                <td>${formatCurrencyWholeNumber(data.dividend_payout_before_select_age[index])}</td>
                <td>${formatCurrencyWholeNumber(data.dividend_payout_no_reinvest[index])}</td>
                <td>${formatCurrencyWholeNumber(data.total_payout[index])}</td>
                <td>${formatCurrencyWholeNumber(data.total_asset_value_with_no_reinvest[index])}</td>
                </tr>`);
              });

              $('#investment-illustrator-modal').modal();
            },
          );
        }
      }
    });
  }

  handleFormatMoneyFields() {
    let eventBaseOnBrowser = this.detectBrowserToUseExactEvent();
    $(this.investmentIllustratorForm).on(
      `${eventBaseOnBrowser}`,
      `${this.depositAmount}, ${this.yearlyPayoutAmount}`,
      (e) => {
        autoFormatMoneyFields(e.target);
      },
    );

    $('.lumpsum-topup .amount-wrapper input, .lumpsum-withdrawal .amount-wrapper input').on(
      `${eventBaseOnBrowser}`,
      (e) => {
        autoFormatMoneyFields(e.target);
      },
    );
  }

  handleInflationInputValue() {
    if ($(this.inflationRate).prop('disabled') == true) {
      return null;
    } else {
      return $(this.inflationRate).val();
    }
  }

  showLoadingDreamChart() {
    $(this.chartElementContainer).closest('.chart-container').addClass('chart-loading');
  }

  hideLoadingDreamChart() {
    $(this.chartElementContainer).closest('.chart-loading').removeClass('chart-loading');
  }

  setDataSets(data) {
    if ($(this.payoutType).val() == 'fixed_amount') {
      return [
        {
          data: data.cumulativeDeposits,
          label: 'Cumulative Deposits',
          borderColor: 'rgba(0, 51, 160, 0.7)',
          fill: true,
          tension: 0.4,
          backgroundColor: 'rgba(0, 51, 160, 0.7)',
        },
        {
          data: data.payoutAmount,
          label: 'Payout Amount',
          borderColor: 'rgba(236,193,94, 0.7)',
          fill: true,
          tension: 0.4,
          backgroundColor: 'rgba(236,193,94, 0.7)',
        },
        {
          data: data.netInvestmentValueEndOfYear,
          label: 'Net Investment Value at End of Year',
          borderColor: 'rgba(66,188,94,0.7)',
          fill: true,
          tension: 0.4,
          backgroundColor: 'rgba(66,188,94, 0.7)',
        },
      ];
    } else if ($(this.payoutType).val() == 'dividend' && $(this.dividendCheckBox).is(':checked')) {
      return [
        {
          data: data.projectWorthWithoutReinvesting,
          label: 'Projected worth without reinvesting',
          borderColor: 'rgba(0, 51, 160, 0.7)',
          fill: true,
          tension: 0.4,
          backgroundColor: 'rgba(0, 51, 160, 0.7)',
        },
        {
          data: data.projectWorthWithReinvesting,
          label: 'Projected worth with reinvesting',
          borderColor: 'rgba(66,188,94,0.7)',
          fill: true,
          tension: 0.4,
          backgroundColor: 'rgba(66,188,94,0.7)',
        },
        {
          data: data.yearlyPayoutWithReinvest,
          label: 'Payout Amount',
          borderColor: 'rgba(236,193,94, 0.7)',
          fill: true,
          tension: 0.4,
          backgroundColor: 'rgba(236,193,94, 0.7)',
        },
      ];
    } else {
      return [
        {
          data: data.cumulativeDepositsDividend,
          label: 'Cumulative Deposits',
          borderColor: 'rgba(0, 51, 160, 0.7)',
          fill: true,
          tension: 0.4,
          backgroundColor: 'rgba(0, 51, 160, 0.7)',
        },
        {
          data: data.yearlyPayoutWithNoReinvest,
          label: 'Payout Amount',
          borderColor: 'rgba(236,193,94, 0.7)',
          fill: true,
          tension: 0.4,
          backgroundColor: 'rgba(236,193,94, 0.7)',
        },
        {
          data: data.netInvestmentValueDividendWithoutReinvest,
          label: 'Net Investment Value at End of Year',
          borderColor: 'rgba(66,188,94,0.7)',
          fill: true,
          tension: 0.4,
          backgroundColor: 'rgba(66,188,94,0.7)',
        },
      ];
    }
  }

  setTooltip(age_range) {
    if (
      $(this.payoutType).val() == 'fixed_amount' ||
      ($(this.payoutType).val() == 'dividend' && $(this.dividendCheckBox).is(':checked'))
    ) {
      return {
        mode: 'index',
        position: 'tooltipPositionCustom',
        callbacks: {
          label: (context) => {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }

            if (context.parsed.y !== null) {
              label += formatCurrencyWholeNumber(context.parsed.y);
            }
            return label;
          },
        },
      };
    } else {
      return {
        mode: 'index',
        position: 'tooltipPositionCustom',
        callbacks: {
          label: (context) => {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }

            if (context.parsed.y !== null && label === 'Dividend Payouts Received: ') {
              label += formatCurrencyWholeNumber(this.dividendPayoutReceived[age_range[context.parsed.x]]);
            } else {
              label += formatCurrencyWholeNumber(context.parsed.y);
            }

            return label;
          },
        },
      };
    }
  }

  updateOptionsChart() {
    return {
      tooltip: this.setTooltip(this.inclusiveRange(Number($(this.ageFrom).val()), Number($(this.ageTo).val()), 1)),
      legend: {
        position: 'bottom',
      },
    };
  }

  getExistLumpsumTopupValue() {
    let initialTopupValue = [];
    if ($('.lumpsum-topup').length > 0) {
      for (let i = 1; i < $('.lumpsum-topup').length + 1; i++) {
        initialTopupValue.push({
          age: Number($(`#topup_age_value_${i}`).val()),
          value: formatMoneyToNumber($(`#topup_amount_${i}`).val()),
          topup_id: i,
        });
      }
    }

    return initialTopupValue;
  }

  getExistLumpsumwithdrawalValue() {
    let initialwithdrawalValue = [];
    if ($('.lumpsum-withdrawal').length > 0) {
      for (let i = 1; i < $('.lumpsum-withdrawal').length + 1; i++) {
        initialwithdrawalValue.push({
          age: Number($(`#withdrawal_age_value_${i}`).val()),
          value: formatMoneyToNumber($(`#withdrawal_amount_${i}`).val()),
          withdrawal_id: i,
        });
      }
    }

    return initialwithdrawalValue;
  }

  detectBrowserToUseExactEvent() {
    if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
      return 'change';
    } else {
      return 'keyup';
    }
  }
}
