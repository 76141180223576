import { formatCurrency, formatCurrencyWithoutSign } from '@/layouts/application/utils/formats';
import DreamGiftingForm from '../dreams/dreamGiftingForm';
import { getAge } from '@/layouts/application/utils/calculateAgeByInput';

export default class GiftingCalculatorClientForm {
  setup() {
    this.prepareModal();
  }

  bothCheckbox = [
    {
      input: '#gifting_calculator_client_expense_name',
      selector: '.expense-gift-name-error',
    },
    {
      input: '#gifting_calculator_client_expense_amount',
      selector: '.expense-gift-amount-error',
    },
    {
      input: '#gifting_calculator_client_gifting_name',
      selector: '.dream-gift-name-error',
    },
    {
      input: '#date-expected-input',
      selector: '.dream-gift-start-date-error',
    },
    {
      input: '#gifting_calculator_client_gifting_cost',
      selector: '.dream-gift-cost-error',
    },
  ];

  expenseCheckbox = [
    {
      input: '#gifting_calculator_client_expense_name',
      selector: '.expense-gift-name-error',
    },
    {
      input: '#gifting_calculator_client_expense_amount',
      selector: '.expense-gift-amount-error',
    },
  ];

  dreamCheckbox = [
    {
      input: '#gifting_calculator_client_gifting_name',
      selector: '.dream-gift-name-error',
    },
    {
      input: '#date-expected-input',
      selector: '.dream-gift-start-date-error',
    },
    {
      input: '#gifting_calculator_client_gifting_cost',
      selector: '.dream-gift-cost-error',
    },
  ];

  prepareModal() {
    $('body').on('modal:afterSetContent', '#gifting-calculator-client', () => {
      let sumValues = [];
      this.defaultMoneyValue();
      this.handleErrorFields();
      new DreamGiftingForm().setup();

      $('#gifting_calculator_client_gifting_source').on('change', () => {
        $('.cash-surplus .input-value input').each(function () {
          $(this).prop('checked', false);
        });
        $('.liquidable-assets .input-value input').each(function () {
          $(this).prop('checked', false);
        });
        $('.net-income .input-value input').each(function () {
          $(this).prop('checked', false);
        });
        if ($('#gifting_calculator_client_gifting_source').val() == 'cash_surplus') {
          $('.net-income').addClass('d-none');
          $('.cash-surplus').removeClass('d-none');
          $('.liquidable-assets').addClass('d-none');
        } else if ($('#gifting_calculator_client_gifting_source').val() == 'liquidable_assets') {
          $('.net-income').addClass('d-none');
          $('.cash-surplus').addClass('d-none');
          $('.liquidable-assets').removeClass('d-none');
        } else {
          $('.net-income').removeClass('d-none');
          $('.cash-surplus').addClass('d-none');
          $('.liquidable-assets').addClass('d-none');
        }
        sumValues = [];
        this.updateGiftingValueBaseOnInput(
          sumValues,
          $('#gifting_calculator_client_gifting_interval').val(),
          $('#gifting_calculator_client_percent').val(),
        );
      });

      $('.net-income .input-value input, .cash-surplus .input-value input, .liquidable-assets .input-value input').on(
        'change',
        () => {
          sumValues = [];
          $('.net-income .input-value input:checked').each(function () {
            sumValues.push(this.value);
          });
          $('.cash-surplus .input-value input:checked').each(function () {
            sumValues.push(this.value);
          });

          $('.liquidable-assets .input-value input:checked').each(function () {
            sumValues.push(this.value);
          });
          this.updateGiftingValueBaseOnInput(
            sumValues,
            $('#gifting_calculator_client_gifting_interval').val(),
            $('#gifting_calculator_client_percent').val(),
          );
        },
      );

      $('#gifting_calculator_client_percent').on('input', () => {
        this.updateGiftingValueBaseOnInput(
          sumValues,
          $('#gifting_calculator_client_gifting_interval').val(),
          $('#gifting_calculator_client_percent').val(),
        );
      });

      $('#gifting_calculator_client_gifting_interval').on('change', () => {
        this.updateGiftingValueBaseOnInput(
          sumValues,
          $('#gifting_calculator_client_gifting_interval').val(),
          $('#gifting_calculator_client_percent').val(),
        );
      });

      $('.gifting-expense-checkbox').on('change', () => {
        if ($('#gifting_calculator_client_gifting_expense_checkbox').is(':checked')) {
          $('.gifting-expense-fields').removeClass('d-none');
        } else {
          $('.gifting-expense-fields').addClass('d-none');
        }
      });

      $('.gifting-dream-checkbox').on('change', () => {
        if ($('#gifting_calculator_client_gifting_dream_checkbox').is(':checked')) {
          $('.gifting-dream-fields').removeClass('d-none');
        } else {
          $('.gifting-dream-fields').addClass('d-none');
        }
      });

      $('.gifting-expense-checkbox, .gifting-dream-checkbox').on('change', () => {
        if (
          $('#gifting_calculator_client_gifting_dream_checkbox').is(':checked') ||
          $('#gifting_calculator_client_gifting_expense_checkbox').is(':checked')
        ) {
          $('#create-gifting-button').removeClass('disabled-link');
        } else {
          $('#create-gifting-button').addClass('disabled-link');
        }
      });

      let initialValue = getAge(
        $('#date-of-birth-data').data('dob-data'),
        new Date($('#gifting_calculator_client_end_date').val()),
      );
      $('#age-value').html(initialValue);

      $('#gifting_calculator_client_end_date').on('change', () => {
        let ageValue = getAge(
          $('#date-of-birth-data').data('dob-data'),
          new Date($('#gifting_calculator_client_end_date').val()),
        );
        $('#age-value').html(ageValue);
      });
    });
  }

  defaultMoneyValue() {
    $('#gifting-value-client').text(formatCurrency('0'));
  }

  updateGiftingValueBaseOnInput(value, interval, percent) {
    $.post(
      Routes.calculate_consultant_gifting_calculators_path({
        value: value,
        interval: interval,
        percent: percent,
      }),
    ).done((data) => {
      $('#gifting-value-client').text(formatCurrency(data['data']));
      $('#gifting_calculator_client_expense_amount').val(formatCurrencyWithoutSign(data['data']));
      $('#gifting-client-value-input').val(data['data']);
      $('#gifting_calculator_client_expense_interval_type').select2().val(interval).trigger('change');
    });
  }

  handleErrorFields() {
    $('#create-gifting-button').on('click', () => {
      if (
        $('#gifting_calculator_client_gifting_expense_checkbox').is(':checked') &&
        $('#gifting_calculator_client_gifting_dream_checkbox').is(':checked')
      ) {
        this.checkShowError(this.bothCheckbox);
      } else if ($('#gifting_calculator_client_gifting_expense_checkbox').is(':checked')) {
        this.checkShowError(this.expenseCheckbox);
      } else if ($('#gifting_calculator_client_gifting_dream_checkbox').is(':checked')) {
        this.checkShowError(this.dreamCheckbox);
      }
    });
  }

  checkShowError(arr) {
    arr.forEach((a) => {
      if ($(a.input).val().length == 0) {
        $(a.selector).html('This is a compulsory field.');
      }
    });
  }
}
