import Konva from 'konva';

const pencilWidth = 5;
const eraserWidth = 30;
const defaultColor = '#00008b'
const opacityClass = 'opacity-50';

export default class OverlayCanvas {
  constructor() {
    this.enableOverlayCanvasBtn = '#show-overlay-canvas-btn';
    this.enableOverlayCanvasMessage = '#enable-overlay-canvas-message';
    this.disableOverlayCanvasMessage = '#disable-overlay-canvas-message';
    this.overlayCanvasContainer = '.overlay-canvas-container';
    this.canvasContainer = '.konvajs-content';
    this.overlayCanvasID = 'overlay-canvas';
    this.konvaOptions = '.konva-options';
    this.eraserSelector = '#konva-eraser';
    this.pencilSelector = '#konva-pencil';
    this.handSelector = '#konva-hand';
    this.colorPickerSelector = '#konva-color-picker';
    this.eraserSizeSelector = '#eraser-size';
    this.circleCursorSelector = '#circle-cursor';
    this.konvaOptionsBtn = '.konva-options button';
    this.selectedColor = defaultColor;
    this.eraserWidth = eraserWidth;
    this.pencilWidth = pencilWidth;
    this.context = null
    this.canvas = null;
    this.konvaStage = null;
    this.image = null;
    this.isPaint = true;
    this.mode = 'brush'
    this.lastPointerPosition = null;
  }

  setup() {
    this.resizeOverlayCanvasContainer();
    this.konvaSetup();
    this.handleKonvaOptions();

    $(this.enableOverlayCanvasBtn).on('click', () => {
      this.toggleOverlayCanvas();
      $('.question-section').hide();
    });

    const resizeObserver = new ResizeObserver(() => {
      this.resizeOverlayCanvasContainer()
    })
    resizeObserver.observe(document.body);
  }

  toggleOverlayCanvas() {
    if ($(this.enableOverlayCanvasMessage).is(':visible')) {
      $(this.enableOverlayCanvasMessage).hide();
      $(this.disableOverlayCanvasMessage).show();
      $(this.overlayCanvasContainer).show();
      $(this.konvaOptions).removeClass('d-none');
    } else {
      $(this.enableOverlayCanvasMessage).show();
      $(this.disableOverlayCanvasMessage).hide();
      $(this.overlayCanvasContainer).hide();
      $(this.konvaOptions).addClass('d-none');
      this.clearOverlayCanvas();
    }
  }

  konvaSetup() {
    this.setupDefault();
    this.bindingStageEvents();
    this.setCursorSize();
  }

  setupDefault() {
    this.stage = new Konva.Stage({
      container: this.overlayCanvasID,
      width: $(document).width(),
      height: $(document).height(),
    });

    this.layer = new Konva.Layer();
    this.stage.add(this.layer);

    this.canvas = document.createElement('canvas');
    this.canvas.width = this.stage.width();
    this.canvas.height = this.stage.height();
    this.context = this.canvas.getContext('2d');
    this.isPaint = false;
    this.image = new Konva.Image({
      image: this.canvas,
      x: 0,
      y: 0,
    });
    this.layer.add(this.image);
  }

  resizeOverlayCanvasContainer() {
    $(this.overlayCanvasContainer).width($(document).width());
    $(this.overlayCanvasContainer).height($(document).height());

    if (this.canvas) {
      this.canvas.width = this.stage.width();
      this.canvas.height = this.stage.height();
    }
  }

  bindingStageEvents() {
    this.image.on('mousedown touchstart', () => {
      this.isPaint = true;
      this.lastPointerPosition = this.stage.getPointerPosition();
    });

    this.stage.on('mouseup touchend', () => {
      this.isPaint = false;
    });

    this.stage.on('mousemove touchmove', (e) => {
      this.setCursorPosition(e);

      if (!this.isPaint) {
        return;
      }

      if (this.mode === 'brush') {
        this.context.globalCompositeOperation = 'source-over';
        this.setPencilConfig()
      } else if (this.mode === 'eraser') {
        this.context.globalCompositeOperation = 'destination-out';
        this.setEraserConfig();
      }
      this.context.beginPath();

      let localPos = {
        x: this.lastPointerPosition.x - this.image.x(),
        y: this.lastPointerPosition.y - this.image.y(),
      };
      this.context.moveTo(localPos.x, localPos.y);
      const pos = this.stage.getPointerPosition();
      localPos = {
        x: pos.x - this.image.x(),
        y: pos.y - this.image.y(),
      };
      this.context.lineTo(localPos.x, localPos.y);
      this.context.closePath();
      this.context.stroke();

      this.lastPointerPosition = pos;
      this.layer.batchDraw();
    });
  }

  handleKonvaOptions() {
    $(this.eraserSelector).on('click', (event) => {
      $(this.canvasContainer).css('pointer-events', 'auto')
      this.handleToggleButton($(this.eraserSelector));

      this.mode = 'eraser'
      $(this.eraserSizeSelector).show();
      $(this.colorPickerSelector).hide();
      $(this.circleCursorSelector).css({
        display: 'block'
      });
      this.setCursorPosition(event);
    })

    $(this.pencilSelector).on('click', () => {
      this.mode = 'brush'
      $(this.eraserSizeSelector).hide();
      $(this.colorPickerSelector).show();
      $(this.canvasContainer).css('pointer-events', 'auto')
      this.handleToggleButton($(this.pencilSelector));
      this.clearCircleCursor();
    })

    const $colorPickerSelector = $(this.colorPickerSelector);
    $colorPickerSelector.val(defaultColor)
    $colorPickerSelector.on('change', (e) => {
      this.selectedColor = $(e.target).val();
      this.context.strokeStyle = this.selectedColor;
      this.setPencilConfig();
    })

    const $eraserSizeSelector = $(this.eraserSizeSelector);
    $eraserSizeSelector.on('change', () => {
      this.eraserWidth = $eraserSizeSelector.val();
      this.setEraserConfig();
    })

    $(this.handSelector).on('click', () => {
      $(this.eraserSizeSelector).hide();
      $(this.colorPickerSelector).hide();
      $(this.canvasContainer).css('pointer-events', 'none')
      this.handleToggleButton($(this.handSelector));
      this.clearCircleCursor();
    })
  }

  clearOverlayCanvas() {
    $(this.konvaOptions).addClass('d-none');
    this.clearCircleCursor();
    this.canvas.clear();
  }

  clearCircleCursor() {
    $(this.circleCursorSelector).css({
      display: 'none'
    })
  }

  handleToggleButton($element) {
    $(this.konvaOptionsBtn).addClass(opacityClass);
    $element.removeClass(opacityClass)
  }

  setPencilConfig() {
    this.context.fillStyle = this.selectedColor;
    this.context.strokeStyle = this.selectedColor;
    this.context.lineJoin = 'round';
    this.context.lineWidth = this.pencilWidth;
  }

  setEraserConfig() {
    this.context.lineWidth = this.eraserWidth;
    this.setCursorSize();
  }

  setCursorSize() {
    $(this.circleCursorSelector).css({
      'width': this.eraserWidth + 'px',
      'height': this.eraserWidth + 'px',
    })
  }

  setCursorPosition(e) {
    let positionX, positionY;
    if(e.type.includes(`touch`)) {
      const { touches, changedTouches } = e.evt.originalEvent ?? e.evt;
      const touch = touches[0] ?? changedTouches[0];
      positionX = touch.clientX;
      positionY = touch.clientY;
    } else if (e.type.includes(`mouse`)) {
      positionX = e.evt.clientX;
      positionY = e.evt.clientY;
    }

    if(this.mode !== 'eraser') return;

    $(this.circleCursorSelector).css({
      'left': positionX - this.context.lineWidth / 2 + 'px',
      'top': positionY - this.context.lineWidth / 2 + 'px'
    });
  }
}
