import Chart from 'chart.js/auto';
import { formatCurrency } from '@/layouts/application/utils/formats';
import DreamChart from './dreamChart';
import SimulatedInvestmentForm from '../simulatedInvestmentForm';
import ExistingAssetForm from '../existingAssetForm';
import externalTooltipHandler from '@/layouts/application/utils/charts/modifyTooltipRetirementChart';
import Tooltip from '@/layouts/application/utils/charts/tooltipPositionCustom';
import {
  EMPTY,
  GREEN,
  RETIREMENT_CURRENT_SAVING_BODER_COLOR,
  RETIREMENT_FUNTS_BODER_COLOR,
  RETIREMENT_DESIRED_BODER_COLOR,
  RETIREMENT_SIMULATED_BODER_COLOR,
  RETIREMENT_DREAM_COLOR,
} from '@/layouts/application/utils/charts/chartColors';
import { convertYearByAge } from '@/layouts/application/utils/calculateAgeByInput';
import showAttentionNeededForEvents from '../../shared/showEventProgressByPercentage';
const images = require.context('@/images', true);
const imagePath = (name) => images(name, true);

export default class DreamRetirementChart extends DreamChart {
  constructor(...args) {
    super(...args);

    this.simulatedInsurancePolicyActivateCheckboxSelector =
      '.add-simulated-insurance-policy-to-retirement-plan-checkbox';
    this.simulatedEventActivateCheckboxSelector = '.add-to-retirement-plan-checkbox';
    this.existingCoverageWrapper = '.existing-coverage-wrapper';
    this.existingCoverageActivateCheckboxSelector = '.existing-coverage-checkbox';
    this.totalRetirementAmountSelector = '.total-retirement-amount';
    this.retirementAmountInfoSelector = '.retirement-amount-info';
    this.rangeStartYearSelector = '.retirement-dream-year-range .range-start-year';
    this.rangeEndYearSelector = '.retirement-dream-year-range .range-end-year';
    this.rangeForwardYearSelector = '.retirement-dream-year-range .forward-year-icon';
    this.rangeBackYearSelector = '.retirement-dream-year-range .back-year-icon';
    this.maxYaxis = 0;
    this.dreamListSelector = '.dreams-wrapper .dream-card-wrapper';
    this.dreamCardIconSelector = '.card-icon';
    this.dreamFeasibilityStatus = '.feasibility-status';
    this.toggleEventSelector = '.toggle-event-btn';
    this.isRendered = 0;
    this.retirementTargetImgSelector = '#retirement-target-img';
    this.simulatedInvestmentFormSelector = '.si-form';
    this.retirementNoticeInforSectionSelector = '.retirement-notice-info-section';
    this.simulatedEventsSurplusWrapper = '#simulated-events-surplus';
    this.additionalIncomeWrapper = '#additional-income';
    this.futureIncome = '.future-income';
  }

  isLifeTimeView = false;
  retirementDreamChart = undefined;

  setup() {
    this.setupChartRangeYear();
    this.setOnChangeViewType();
    this.presentDataToChart();
    this.updateRetirementTargetInfoForIndividualAccounts();
    this.addEventForPresentDreamCheckbox();
    this.setupSimulatedInvestments();
    this.setupExistingAssets();
    this.addEventForActivateSimulatedInvestmentCheckbox();
    this.addEventForActivateSimulatedInsurancePolicyCheckbox();
    this.addEventForActivateSimulatedEventCheckbox();
    this.addEventForActivateExistingAssetCheckbox();
    this.addEventForActivateExistingEventCheckbox();
    this.addEvenForDreamToggleItem();
    this.addEventForConditionSimulatedInvestment();
  }

  refreshChartDataWhenChangingRangeYear() {
    this.presentDataToChart();
  }

  setupSimulatedInvestments() {
    new SimulatedInvestmentForm(this).setup();
  }

  setupExistingAssets() {
    new ExistingAssetForm(this).setup();
  }

  presentDataToChart() {
    this.showLoadingDreamChart();
    $.post(
      Routes.present_dreams_to_retirement_chart_consultant_client_dreams_path({
        client_id: this.clientId,
        type: 'retirement',
      }),
      {
        data: {
          is_lifetime_view: this.isLifeTimeView,
          range_start_age: this.currentRangeStartAge,
        },
      },
      (data) => {
        this.dataForLabels = data.chart_data.labels;
        this.dataForBeforeRetirement = data.chart_data.data_before_retirement;
        this.dataForAfterRetirement = data.chart_data.data_after_retirement;
        this.dataForRetirementFunds = data.chart_data.data_retirement_funds;
        this.dataForRetirementAmount = data.chart_data.data_retirement_amount;
        this.dataAllSimulatedInvestment = data.chart_data.data_all_simulated_investments;
        this.dataForFirstSimulatedInvestment = data.chart_data.data_first_simulated_investment;
        this.dataForSecondSimulatedInvestment = data.chart_data.data_second_simulated_investment;
        this.dataColorsRetirement = data.chart_data.data_colors;
        this.totalRetirementAmount = data.chart_data.total_retirement_amount;
        this.retirementAmountInfo = data.retirement_info;
        this.dreamFeasibilitiesData = data.chart_data.dream_feasibilities;
        this.retirementEnoughNotice = data.chart_data.retirement_info.retirement_enough_notice;
        this.updateRetirementInfo();
        this.presentIconsToChart(data.icons_data);
        this.updateDeamFeasibilities();
        this.updateRetirementTargetImage();
        this.drawRetirementDreamChart();
      },
    );
  }

  presentSimulatedEventsSurplus() {
    $.get(Routes.simulated_events_surplus_consultant_client_simulated_events_path({ client_id: this.clientId })).done(
      (data) => {
        if (data.html != '') {
          $(this.additionalIncomeWrapper).addClass('d-flex');
          $(this.additionalIncomeWrapper).removeClass('d-none');
        } else if ($(this.futureIncome).length == 0) {
          $(this.additionalIncomeWrapper).addClass('d-none');
          $(this.additionalIncomeWrapper).removeClass('d-flex');
        }
        $(this.simulatedEventsSurplusWrapper).html(data.html);
      },
    );
  }

  updateRetirementTargetInfoForIndividualAccounts() {
    const mergedContactIds = $('#individual-contacts').data('merged-contact-ids');
    if (mergedContactIds.length == 0) return;

    mergedContactIds.forEach((clientId, index) => {
      $.post(
        Routes.present_dreams_to_retirement_chart_consultant_client_dreams_path({
          client_id: clientId,
          type: 'retirement',
        }),
        {
          data: {
            is_lifetime_view: this.isLifeTimeView,
            range_start_age: this.currentRangeStartAge,
          },
        },
        (data) => {
          const totalRetirementAmount = data.chart_data.total_retirement_amount;
          const retirementEnoughNotice = data.chart_data.retirement_info.retirement_enough_notice;
          const retirementNumber = index + 1;

          $(`.total-retirement-amount-${retirementNumber}`).html(formatCurrency(totalRetirementAmount));

          const image = retirementEnoughNotice
            ? imagePath('./icons/dream/retirement-target-green.svg')
            : imagePath('./icons/dream/retirement-target.svg');
          $(`#retirement-target-img-${retirementNumber}`).attr('src', image);
        },
      );
    });
  }

  updateDeamFeasibilities() {
    Array.from(document.querySelectorAll(this.dreamListSelector)).forEach((element) => {
      const dreamId = element.dataset.dreamId;

      const feasibily = this.dreamFeasibilitiesData[dreamId];
      if (feasibily) {
        const cardIconSelector = $(element.querySelector(this.dreamCardIconSelector));
        const feasibilityStatus = $(element.querySelector(this.dreamFeasibilityStatus));
        const feasibilityWithSaving = feasibily['feasibility_with_saving'];
        const feasibilityWithFirstSi = feasibily['feasibility_with_first_si'];
        const feasibilityWithSecondSi = feasibily['feasibility_with_second_si'];

        if (feasibilityWithSaving) {
          cardIconSelector.removeClass('red');
          cardIconSelector.addClass('green');
          feasibilityStatus.html('');
        } else {
          cardIconSelector.removeClass('green');
          cardIconSelector.addClass('red');
          feasibilityStatus.html('');
        }

        if (!feasibilityWithSaving) {
          if (feasibilityWithFirstSi && feasibilityWithSecondSi) {
            feasibilityStatus.html('Achievable with Simulated Investment 1 or 2');
          } else if (feasibilityWithFirstSi) {
            feasibilityStatus.html('Achievable with Simulated Investment 1');
          } else if (feasibilityWithSecondSi) {
            feasibilityStatus.html('Achievable with Simulated Investment 2');
          }
        }
      }
    });
  }

  updateRetirementTargetImage() {
    const image = this.retirementEnoughNotice
      ? imagePath('./icons/dream/retirement-target-green.svg')
      : imagePath('./icons/dream/retirement-target.svg');

    $(this.retirementTargetImgSelector).attr('src', image);
  }

  drawRetirementDreamChart() {
    this.initRetirementDreamChart({ element: $(this.chartElementContainer) });
  }

  addEventForActivateSimulatedInsurancePolicyCheckbox() {
    $(this.simulatedInsurancePolicyActivateCheckboxSelector).on('change', ({ target }) => {
      const simulated_insurance_policy_id = $(target).val();
      const is_show = $(target).is(':checked');
      const params = {
        client_id: this.clientId,
        simulated_insurance_policy_id: simulated_insurance_policy_id,
        is_show: is_show,
      };
      $.post(Routes.update_is_show_consultant_client_simulated_insurance_policies_path(params)).done(() => {
        this.presentDataToChart();
        this.presentSimulatedEventsSurplus();

        $.get(Routes.existing_coverage_consultant_client_dreams_path({ client_id: this.clientId })).done((data) => {
          $(this.existingCoverageWrapper).html(data);
          this.addEventForActivateExistingEventCheckbox();
          this.reloadTheCashSurplusNotice();
          this.setOnClickToggleEvent();
        });
      });
    });
  }

  addEventForActivateSimulatedEventCheckbox() {
    $(this.simulatedEventActivateCheckboxSelector).on('change', ({ target }) => {
      const simulated_event_id = $(target).val();
      const is_show = $(target).is(':checked');
      const params = { client_id: this.clientId, simulated_event_id: simulated_event_id, is_show: is_show };
      $.post(Routes.update_is_show_consultant_client_simulated_events_path(params)).done(() => {
        this.presentDataToChart();
        this.presentSimulatedEventsSurplus();
      });
    });
  }

  addEventForActivateExistingEventCheckbox() {
    $(this.existingCoverageActivateCheckboxSelector).on('change', ({ target }) => {
      const event_id = $(target).val();
      const is_show = $(target).is(':checked');
      const params = { client_id: this.clientId, event_id: event_id, is_show: is_show };
      $.post(Routes.update_is_show_consultant_client_events_path(params)).done(() => {
        this.presentDataToChart();
        this.presentSimulatedEventsSurplus();
      });
    });
  }

  addEventForConditionSimulatedInvestment() {
    $('#simulated-investments .nav-link').on('click', ({ target }) => {
      const conditionSimulatedInvestment = $(target).data('tab');

      $.ajax({
        url: Routes.update_condition_simulated_investment_consultant_client_path(this.clientId),
        type: 'PUT',
        data: { condition_simulated_investment: conditionSimulatedInvestment },
        success: () => {
          this.presentDataToChart();
          this.reloadTheCashSurplusNotice();
        },
      });
    });
  }

  reloadTheCashSurplusNotice() {
    const $form = $(this.simulatedInvestmentFormSelector).first();
    if ($form.length) {
      const siIndex = $form.data('si-index');

      $.ajax({
        url: $form.attr('action') + `?si_index=${siIndex}&skip_update=true`,
        type: 'PUT',
        data: {},
        success: (data) => {
          $(this.retirementNoticeInforSectionSelector).replaceWith(data.retirement_notice_info_form);
        },
      });
    }
  }

  addEventForActivateExistingAssetCheckbox() {
    $(this.existingAssetActivateCheckboxSelector).on('change', () => {
      this.presentDataToChart();
    });
  }

  initRetirementDreamChart({ element }) {
    const data = {
      labels: this.dataForLabels,
      datasets: this.setDataSets(),
    };
    const _this = this;
    const options = this.getDefaultChartOption();

    if (this.retirementDreamChart) {
      data.datasets = data.datasets.filter((e) => e.label !== 'icons');
      this.retirementDreamChart.data = data;
      this.retirementDreamChart.update();
    } else {
      this.retirementDreamChart = new Chart(element, {
        data: data,
        options,
        plugins: [
          {
            afterUpdate: (chart) => {
              //const ctx = chart.ctx;
              //const { chartArea: { top, right, bottom, left, width, height }, scales: { x, y } } = chart;
              //const yAxisIndexZero = y.ticks.findIndex((e) => e.value == 0)
              //const yAxisMaxVal = y?.ticks[yAxisIndexZero + 1]?.value || 500000;
              //const datasets = chart.data.datasets;
              //if(isRenderedIcon && this.maxYaxis === yAxisMaxVal) return;

              //if(isRenderedIcon && this.maxYaxis !== yAxisMaxVal) {
              //if(countIcons <= 0){
              //this.triggerCreateIcons(chart);
              //}
              //return;
              //}
              this.isRendered++;
              if (this.isRendered === 2) {
                this.isRendered = 0;
                this.triggerCreateIcons(chart);
              }
              this.hideLoadingDreamChart();
            },
            afterLayout: (chart) => {
              const ctx = chart.ctx;
              const xAxis = chart.scales.x;
              const gradientStroke = ctx.createLinearGradient(xAxis.left, 0, xAxis.right, 0);
              const gradientBackground = ctx.createLinearGradient(xAxis.left, 0, xAxis.right, 0);
              for (let t = 0; t <= 1; t++) {
                const dataset = chart.data.datasets[t];
                if (dataset.data.length == 1) break;
                dataset.colors.forEach((c, i) => {
                  if (c == null) return;
                  const stop = (1 / (dataset.colors.length - 1)) * i;
                  gradientStroke.addColorStop(stop, _this.getColor(dataset.colors[i]));
                  gradientBackground.addColorStop(stop, _this.getColor(dataset.colors[i], stop));
                });
                dataset.backgroundColor = gradientBackground;
                dataset.borderColor = gradientStroke;
                dataset.pointBorderColor = gradientStroke;
                dataset.pointBackgroundColor = gradientStroke;
                dataset.pointHoverBorderColor = gradientStroke;
                dataset.pointHoverBackgroundColor = gradientStroke;
              }
            },
          },
        ],
      });
    }

    return this.updateSimulatedLineChart();
  }

  triggerCreateIcons(chart) {
    const y = chart.scales.y;
    const yAxisIndexZero = y.ticks.findIndex((e) => e.value == 0);
    const yAxisMaxVal = y.ticks?.[yAxisIndexZero + 1]?.value || 500000;
    const datasets = chart.data.datasets.filter((e) => e.label != 'icons');
    const data = [];
    this.triggerRenderIcons(data, yAxisMaxVal);
    data.forEach((d) => {
      datasets.push(d);
    });
    chart.data.datasets = datasets;
    this.maxYaxis = yAxisMaxVal;
    chart.update();
  }

  getColor(color, opacity = '1') {
    if (color == 'yellow') {
      return `rgba(232,176,18, ${opacity})`;
    } else if (color == 'green') {
      return `rgba(40, 180, 70, ${opacity})`;
    } else {
      return `rgba(207, 0, 0, ${opacity})`;
    }
  }

  presentIconsToChart(data) {
    this.dataForIcons = data;
    this.datasetIconLength = 0;
    this.dataForIcons.forEach((e) => {
      if (e == null) return;
      this.datasetIconLength = e.length > this.datasetIconLength ? e.length : this.datasetIconLength;
    });
  }

  triggerRenderIcons(datasets, maxYaxis) {
    let offsetArr = Array(this.dataForIcons.length).fill(1);
    datasets.push(this.initFirstDatasetIcons());
    for (let i = 1; i < this.datasetIconLength; i++) {
      const data = Array(this.dataForIcons.length).fill(null);
      const values = Array(this.dataForIcons.length).fill(null);
      let isHidden = false;
      const icon = this.dataForIcons.map((e, idx) => {
        if (e == null || e[i] == null) return null;
        const currentType = e[i - 1]['type'];
        const prevType = e[i]['type'];
        if (currentType !== prevType) {
          data[idx] = maxYaxis * offsetArr[idx];
          offsetArr[idx] += 1;
        } else {
          data[idx] = maxYaxis * 0;
        }

        values[idx] = e[i]['total'];
        return e[i]['icon'];
      });
      datasets.push({
        label: 'icons',
        type: 'line',
        data: data,
        fill: false,
        pointStyle: function (context) {
          let tmp;
          if (data[context.dataIndex] === 0) {
            tmp = new Image(0, 0);
          } else {
            tmp = new Image(20, 20);
          }

          if (icon[context.dataIndex] === null) return;
          tmp.src = imagePath(`./icons/retirement/${icon[context.dataIndex]}.svg`);

          return tmp;
        },
        pointRadius: 1,
        pointHoverRadius: 5,
        values: values,
        showLine: false,
        iconIndex: i,
        orderTooltip: 4,
      });
    }

    return datasets;
  }

  initFirstDatasetIcons() {
    const icons = [];
    const dataIcons = [];
    const multiple = [];
    this.dataForIcons.map((e) => {
      if (e == null) {
        icons.push(null);
        dataIcons.push(null);
        multiple.push(false);
      } else {
        icons.push(e[0]['icon']);
        dataIcons.push(0);
        if (e[0]['type'] === e?.[1]?.['type']) {
          multiple.push(true);
        } else {
          multiple.push(false);
        }
      }
    });

    return {
      label: 'icons',
      type: 'line',
      data: dataIcons,
      fill: false,
      pointStyle: function (context) {
        let image = new Image(20, 20);
        if (icons[context.dataIndex] === null || typeof icons[context.dataIndex] === undefined) return;
        let isMultiple = multiple[context.dataIndex] ? '-multiple' : '';
        image.src = imagePath(`./icons/retirement/${icons[context.dataIndex]}${isMultiple}.svg`);

        return image;
      },
      pointRadius: 1,
      pointHoverRadius: 5,
      iconIndex: 0,
      orderTooltip: 4,
    };
  }

  setDataSets() {
    return [
      {
        label: 'Accumulated Assets',
        type: 'line',
        data: this.dataForBeforeRetirement.concat(this.dataForAfterRetirement).filter(Number.isFinite),
        borderColor: RETIREMENT_CURRENT_SAVING_BODER_COLOR,
        colors: this.dataColorsRetirement,
        fill: true,
        pointStyle: 'line',
        borderWidth: 4,
        order: 2,
        backgroundColor: RETIREMENT_CURRENT_SAVING_BODER_COLOR,
        orderTooltip: 3,
      },
      {
        label: 'Retirement Funds',
        type: 'line',
        data: this.dataForRetirementFunds,
        borderColor: RETIREMENT_FUNTS_BODER_COLOR,
        backgroundColor: RETIREMENT_CURRENT_SAVING_BODER_COLOR,
        fill: true,
        pointStyle: 'line',
        borderWidth: 2,
        colors: this.dataColorsRetirement,
        order: 2,
        orderTooltip: 3,
      },
      {
        label: 'Desired Annual Retirement Income',
        type: 'bar',
        pointStyle: 'line',
        data: this.dataForRetirementAmount,
        borderColor: RETIREMENT_DESIRED_BODER_COLOR,
        backgroundColor: RETIREMENT_DESIRED_BODER_COLOR,
        order: 1,
        orderTooltip: 3,
      },
    ];
  }

  updateSimulatedLineChart() {
    if (this.dataAllSimulatedInvestment && this.dataAllSimulatedInvestment.length) {
      this.setDataForSimulatedChart(
        'With Simulated Investment',
        this.dataAllSimulatedInvestment,
        RETIREMENT_SIMULATED_BODER_COLOR[2],
        1,
      );
    } else {
      if (this.dataForFirstSimulatedInvestment && this.dataForFirstSimulatedInvestment.length) {
        this.setDataForSimulatedChart(
          'With Simulated Investment 1',
          this.dataForFirstSimulatedInvestment,
          RETIREMENT_SIMULATED_BODER_COLOR[0],
          2,
        );
      }

      if (this.dataForSecondSimulatedInvestment && this.dataForSecondSimulatedInvestment.length) {
        this.setDataForSimulatedChart(
          'With Simulated Investment 2',
          this.dataForSecondSimulatedInvestment,
          RETIREMENT_SIMULATED_BODER_COLOR[1],
          1,
        );
      }
    }

    return this.retirementDreamChart.update();
  }

  setDataForSimulatedChart(label, data, borderColor, orderTooltip) {
    this.retirementDreamChart.data.datasets.push({
      label: label,
      type: 'line',
      data: data,
      borderColor: borderColor,
      fill: false,
      borderDash: [5],
      pointStyle: 'line',
      borderWidth: 4,
      order: 1,
      borderCapStyle: 'round',
      backgroundColor: borderColor,
      orderTooltip: orderTooltip,
    });
  }

  getGradient(ctx, chartArea, colorTop = 'rgba(13, 132, 78, 0.7)', colorBottom = 'rgba(13, 132, 78, 0.1)') {
    const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
    gradient.addColorStop(1, colorTop);
    gradient.addColorStop(0, colorBottom);

    return gradient;
  }

  getDefaultChartOption() {
    return {
      scales: {
        x: {
          grid: {
            display: false,
            borderWidth: 0,
          },
          ticks: {
            maxTicksLimit: 15,
            maxRotation: 0,
          },
        },
        y: {
          offset: true,
          grid: {
            borderWidth: 0,
            lineWidth: 1,
            color: '#EEEEEE',
          },
        },
      },
      plugins: {
        legend: {
          position: 'bottom',
          padding: 10,
          labels: {
            usePointStyle: true,
            padding: 20,
            filter: function (item, chart) {
              return item.text !== 'icons 2' && item.text !== 'icons';
            },
            generateLabels: this.generateLabelsWithGradientLegend,
          },
        },
        tooltip: {
          mode: 'index',
          position: 'tooltipPositionCustom',
          backgroundColor: '#EEEEEE',
          padding: 15,
          titleColor: '#303030',
          titleFont: { weight: 'bold', size: 14 },
          bodyColor: '#303030',
          cornerRadius: 10,
          usePointStyle: true,
          enabled: false,
          external: externalTooltipHandler,
          itemSort: (a, b) => {
            return a.dataset.orderTooltip - b.dataset.orderTooltip;
          },
          callbacks: {
            title: (context) => {
              if (this.isLifeTimeView) {
                const currentYear = this.currentRangeStartYear;
                const year = currentYear + context[0].dataIndex;
                const age = context[0].label;
                return `${year} (Age: ${age})`;
              } else {
                let currentYear =
                  parseInt(convertYearByAge(this.clientDob, false)) + parseInt($('span.range-start-year').text());
                if (parseInt(convertYearByAge(this.clientDob, false)) + this.clientAge != new Date().getFullYear()) {
                  currentYear =
                    parseInt(convertYearByAge(this.clientDob, false)) + 1 + parseInt($('span.range-start-year').text());
                }
                const year = currentYear + context[0].dataIndex;
                const age = context[0].label;
                return `${year} (Age: ${age})`;
              }
            },
            label: (context) => {
              let label = context.dataset.label || '';
              if (label === 'icons') {
                const iconDetails = this.dataForIcons?.[context.dataIndex]?.[context.dataset.iconIndex];
                if (iconDetails.type === 'retirement') {
                  label = `${iconDetails.name}: ${formatCurrency(this.totalRetirementAmount)}`;
                } else {
                  label = `${iconDetails.name}: ${formatCurrency(iconDetails.total)}`;
                }

                return label;
              }

              if (label) {
                label += ': ';
              }

              if (context.parsed.y !== null) {
                label += formatCurrency(context.parsed.y);
              } else {
                return;
              }
              return label;
            },
          },
        },
      },
      elements: {
        point: {
          radius: 0,
          hitRadius: 10,
          hoverRadius: 5,
          hoverBorderWidth: 7,
        },
      },
      interaction: {
        mode: 'nearest',
        axis: 'x',
        intersect: false,
      },
    };
  }

  generateLabelsWithGradientLegend(chart) {
    const ctx = chart.ctx;
    const gradientStroke = ctx.createLinearGradient(104, 0, 119, 0);
    gradientStroke.addColorStop(0, RETIREMENT_CURRENT_SAVING_BODER_COLOR);
    gradientStroke.addColorStop(0.2, RETIREMENT_CURRENT_SAVING_BODER_COLOR);
    gradientStroke.addColorStop(0.3, EMPTY);
    gradientStroke.addColorStop(0.4, GREEN);
    gradientStroke.addColorStop(0.6, GREEN);
    gradientStroke.addColorStop(0.7, EMPTY);
    gradientStroke.addColorStop(0.8, RETIREMENT_FUNTS_BODER_COLOR);
    gradientStroke.addColorStop(1, RETIREMENT_FUNTS_BODER_COLOR);
    return chart.data.datasets.map((dataset, i) => ({
      datasetIndex: i,
      text: dataset.label,
      lineDash: dataset.borderDash,
      pointStyle: dataset.pointStyle,
      lineWidth: dataset.borderWidth || 8,
      strokeStyle: dataset.label == 'Accumulated Assets' ? gradientStroke : dataset.borderColor,
    }));
  }

  getAllCheckedExistingAssetGroups() {
    const existingAssetActivatedCheckboxCollection = [...$(this.existingAssetActivateCheckboxSelector)];
    const existingAssetIds = [];

    existingAssetActivatedCheckboxCollection.forEach((element) => {
      if (element.checked) {
        existingAssetIds.push(element.value);
      }
    });

    return existingAssetIds;
  }

  updateRetirementInfo() {
    $(this.totalRetirementAmountSelector).html(formatCurrency(this.totalRetirementAmount));
    $(this.retirementAmountInfoSelector).html(this.retirementAmountInfo);
  }

  setOnClickToggleEvent() {
    $(this.toggleEventSelector).on('click', () => {
      showAttentionNeededForEvents();
    });
  }
}
