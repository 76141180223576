import Raicon from 'raicon';
import { formatCurrency, formatMoneyToNumber } from '@/layouts/application/utils/formats';
import { autoFormatMoneyFields } from '@/layouts/application/utils/handleMoneyField';

class ConsultantGiftingCalculatorsController {
  constructor() {
    this.SELECTORS = {
      sideBar: '#gifting-value-side-bar',
      placeholderValue: '#gifting_calculator_sidebar_placeholder_value',
      percent: '#gifting_calculator_sidebar_percent',
      interval: '#gifting_calculator_sidebar_gifting_interval',
      dreamButton: '#gifting-dream-button',
      expenseButton: '#gifting-expense-button',
      inputSelector: '#gifting_calculator_sidebar_placeholder_value, #gifting_calculator_sidebar_percent',
      openGiftingCalculator: '#open-gifting-calculators',
    };

    this.initializeEventListeners();
  }

  initializeEventListeners() {
    $(document).on('turbolinks:load', () => {
      $('body').on('modal:afterSetContent', this.SELECTORS.openGiftingCalculator, () => {
        this.setupModalHandlers();
      });
    });
  }

  setupModalHandlers() {
    this.defaultGiftingValue();
    this.setupMoneyFieldFormatting();
    this.setupCalculatorInputs();
  }

  setupCalculatorInputs() {
    $(this.SELECTORS.inputSelector).on('input', () => this.calculateGiftingValue());
    $(this.SELECTORS.interval).on('change', () => this.calculateGiftingValue());
  }

  calculateGiftingValue() {
    const value = formatMoneyToNumber($(this.SELECTORS.placeholderValue).val());
    const interval = $(this.SELECTORS.interval).val();
    const percent = $(this.SELECTORS.percent).val();
    this.updateGiftingValueBaseOnInput(value, interval, percent);
  }

  defaultGiftingValue() {
    $(this.SELECTORS.sideBar).text(formatCurrency('0'));
  }

  setupMoneyFieldFormatting() {
    $(this.SELECTORS.inputSelector).on('input', (e) => {
      autoFormatMoneyFields(e.target);
    });
  }

  updateGiftingValueBaseOnInput(value, interval, percent) {
    $.post(
      Routes.calculate_consultant_gifting_calculators_path({
        value,
        interval,
        percent,
      }),
    ).done((data) => {
      $(this.SELECTORS.sideBar).text(formatCurrency(data['data']));
    });
  }
}

Raicon.register('consultant/giftingCalculators', ConsultantGiftingCalculatorsController);
