import Raicon from 'raicon';
import { formatMoneyToNumber } from '@/layouts/application/utils/formats';

class FinancialCalculatorsController {
  constructor() {
    this.SELECTORS = {
      inputs:
        '#financial_calculator_pv_value, #financial_calculator_fv_value, #financial_calculator_pmt_value, #financial_calculator_rate_value, #financial_calculator_nper_value',
      moneyInputs: '#financial_calculator_pv_value, #financial_calculator_fv_value, #financial_calculator_pmt_value',
      calculateButtons:
        '#financial-pv-button, #financial-fv-button, #financial-pmt-button, #financial-rate-button, #financial-nper-button',
      clearButtons: '#financial-clear-button, #close-calculate, .modal-close-btn',
      form: '#financial-calculator-form input.form-control',
    };

    this.initializeEventListeners();
  }

  initializeEventListeners() {
    $(document)
      .on('input', this.SELECTORS.inputs, this.handleInputValidation)
      .on('change', this.SELECTORS.moneyInputs, this.formatMoneyInput)
      .on('click', this.SELECTORS.calculateButtons, this.handleCalculation.bind(this))
      .on('click', this.SELECTORS.clearButtons, () => $(this.SELECTORS.form).val(''));
  }

  handleInputValidation() {
    // Remove invalid characters and ensure only one instance of special characters
    let value = $(this)
      .val()
      .replace(/[^0-9\-,.\s]/g, '');

    // Handle multiple dashes
    if ((value.match(/-/g) || []).length > 1) value = value.replace(/-/g, '');

    // Handle multiple commas
    if ((value.match(/,/g) || []).length > 2) value = value.replace(/,/g, '');

    // Handle multiple periods
    if ((value.match(/\./g) || []).length > 1) {
      value = value.slice(0, value.lastIndexOf('.'));
    }

    $(this).val(value);
  }

  formatMoneyInput() {
    // Format input value to include commas for thousands
    let value = $(this).val();
    if (value === 'nan') return;

    const [integerPart, decimalPart] = value.split('.');
    const formattedInteger = integerPart.replaceAll(',', '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    $(this).val(formattedInteger + (decimalPart ? '.' + decimalPart : ''));
  }

  handleCalculation(event) {
    const clickedButton = $(event.target);
    const clickedId = clickedButton.attr('id');
    const clickedType = clickedButton.text();

    const values = {
      pv: parseFloat(formatMoneyToNumber($('#financial_calculator_pv_value').val())),
      fv: parseFloat(formatMoneyToNumber($('#financial_calculator_fv_value').val())),
      pmt: parseFloat(formatMoneyToNumber($('#financial_calculator_pmt_value').val())),
      rate: parseFloat(formatMoneyToNumber($('#financial_calculator_rate_value').val())),
      nper: parseFloat(formatMoneyToNumber($('#financial_calculator_nper_value').val())),
    };

    if (this.validateInput(clickedButton, values.nper)) {
      this.calculateAndUpdateResult(values, clickedType, clickedId);
    }
  }

  validateInput(button, nperValue) {
    if (
      button.is('#financial-pv-button, #financial-fv-button, #financial-pmt-button, #financial-rate-button') &&
      nperValue < 0
    ) {
      alert('Please enter non-negative periods!');
      return false;
    }
    return true;
  }

  calculateAndUpdateResult(values, type, clickedId) {
    $.ajax({
      url: Routes.financial_calculator_financial_calculators_path(),
      type: 'GET',
      data: {
        pv_value: values.pv,
        fv_value: values.fv,
        pmt_value: values.pmt,
        rate_value: values.rate,
        nper_value: values.nper,
        type: type,
      },
      success: (data) => this.handleCalculationSuccess(data, type, clickedId),
      error: () => console.log('Invalid input. Please enter valid numbers.'),
    });
  }

  handleCalculationSuccess(data, type, clickedId) {
    let result = data.toFixed(2) || '0';

    if (type.toLowerCase() === 'rate') {
      result = data == '92233720368547760' ? 'nan' : `${data.toFixed(2)} %`;
    }

    if (result == '-0.00') result = '0.00';

    $(`#${clickedId}`).closest('.row').find('input').val(result).change();
  }
}

Raicon.register('financialCalculators', FinancialCalculatorsController);
