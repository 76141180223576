import Raicon from 'raicon';
import FamilyMemberForm from './familyMemberForm';
import { add10Referees } from '../add10Referees';
import GiftingCalculatorClientForm from '../clients/giftingCalculatorForm';
import { bulkDeleteItem } from '../shared/bulkDeleteItem';
import OverlayCanvas from '../../shared/overlayCanvas';

class ConsultantFamilyController {
  index() {
    this.bioSetup();
    add10Referees('#open-new-referee', '#multiple-referee-form');
    this.setupGiftingCalculatorForm();
    bulkDeleteItem('#bulk-delete-family-member', '.family-member-checkbox');
    this.setupOverlayCanvas();
  }

  bioSetup() {
    new FamilyMemberForm().setup();
  }

  setupGiftingCalculatorForm() {
    new GiftingCalculatorClientForm().setup();
  }

  setupOverlayCanvas() {
    new OverlayCanvas().setup();
  }
}

Raicon.register('consultant/family', ConsultantFamilyController);
